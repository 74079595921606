import { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';

import { scriptsLoader } from '@pulse-web-platform-core/scripts-loader';

import './assets/css/index.css';

function renderApp() {
  const App = lazy(() => import('./app'));
  const root = ReactDOM.createRoot(
    document.getElementById('pulseRoot') as HTMLElement
  );
  root.render(
    <Suspense fallback={null}>
      <App />
    </Suspense>
  );
}

const host = window.host || '';
const requiredScripts = [
  'https://content.adriver.ru/AdRiverFPS.js',
  `${host}/js/adriver.js`,
  `${host}/js/env-keys.js`,
  `${host}/js/my-tracker.js`,
  `${host}/js/env-urls.js`,
];

scriptsLoader(requiredScripts, () => renderApp());
